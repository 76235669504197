<template>
    <base-section id="map" space="0">
        <iframe
            allowfullscreen
            aria-hidden="false"
            frameborder="0"
            height="500"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.12296844454!2d3.3217667141499603!3d6.506114625171749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8fc681c97927%3A0x87a65f0d4ea0d535!2sSylvaleo%20Global!5e0!3m2!1sen!2sng!4v1604573998802!5m2!1sen!2sng"
            style="border: 0; display: block"
            tabindex="0"
            width="100%"
        />
    </base-section>
</template>

<script>
export default {
    name: 'SectionMap',

    provide: {
        heading: { align: 'center' },
    },
};
</script>
